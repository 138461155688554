@import 'src/styles/base/variables';

.imageDocs{
  max-width: 100px;
  max-height: 70px;
  margin: 0 auto;
  display: block;
}

.upload {
  max-width: 1140px;
  padding: 0 20px;
  margin: 160px auto 40px;

  .ant-table-content {
    @media (max-width: 768px) {
      overflow-x: auto;
    }
  }

  &__tabs .ant-tabs-nav-wrap {
    justify-content: center;

    .ant-tabs-tab {
      padding: 10px;
      font-family: 'Cabin', sans-serif;
      font-size: 20px;

      &:hover {
        color: map_get($colors, green);
      }
    }

    .ant-tabs-tab-active div {      
      color: map_get($colors, green);
    }

    .ant-tabs-ink-bar {
      background: map_get($colors, green);
    }
  }

  &__table {
    th {
      font-size: 22px;
      color: map_get($colors, darkGreen);
    }

    tr td:nth-last-of-type(1) > div {
      display: flex;
      justify-content: flex-end;

      button {
        margin-left: auto;
        margin-right: 10px;

        &:nth-of-type(2) {
          margin-left: 10px;
        }
      }
    }
  }

  .ant-pagination-item-active {
    border-color: map_get($colors, green);

    a {
      color: map_get($colors, green);
    }
  }
}
