@import 'src/styles/base/variables';
.c-footer {
    background-color: map_get($colors, lightGreen);
    padding: 50px 20px 0;

    &__main {
        display: flex;
        flex-wrap: wrap;
        max-width: 1140px;
        padding-bottom: 30px;
        margin: 0 auto;
        border-bottom: 1px solid map_get($colors, middleGray);

        & > div {
            width: calc(100% / 3);

            @media (max-width: 768px) {
                width: 50%;
            }

            .c-footer__title::after {
                @media (max-width: 768px) {
                    width: 60%;
                }
            }
        }

        & > div:nth-last-of-type(1) {
            @media (max-width: 768px) {
                width: 100%;
            }

            .c-footer__title::after {
                @media (max-width: 768px) {
                    width: 30%;
                }
            }
        }

        p {
            font-size: 16px;
            color: map_get($colors, darkGray);
            font-style: italic;
        }
    }

    &__title {
        font-family: 'Cabin', sans-serif;
        font-size: 28px;
        line-height: 1.5;
        color: map_get($colors, black); 
        font-weight: 400;  
        
        &::after {
            content: '';
            display: block;
            height: 2px;
            width: 30%;  
            background: map_get($colors, green);   
        }        
    }

    nav {
        display: flex;

        @media (max-width: 1200px) {
            flex-direction: column;
        }

        a {
            padding: 13px 10px;

            @media (max-width: 1200px) {
                padding: 10px 0 0;
            }

            &:nth-of-type(1) {
                padding-left: 0;
            }
        }
    }

    a {
        display: flex;   
        margin-bottom: 10px;
    }

    &__contact {
        display: flex;
        flex-direction: column;

        span {
            display: inline-block;
        }
    }

    &__icon {
        display: inline-block;
        width: 25px;   
        
        svg {
            font-size: 16px;
            color: map_get($colors, green); 
        }
    }

    .twitter {
        display: block;
        width: min-content;
        padding: 10px 10px 8px 12px;
        margin-top: 10px;
        border-radius: 50%;
        background: map_get($colors, green);

        svg {
            font-size: 25px;
            color: map_get($colors, white);
        }
    }

    &__copyright {
        display: flex;
        justify-content: space-between;
        max-width: 1140px;
        padding: 30px 0;
        margin: 0 auto;

        @media (max-width: 768px) {
            flex-direction: column;
        }

        img {
            max-width: 200px;

            @media (max-width: 768px) {
                max-width: 150px;
                margin-top: 20px;
            }
        }
    }
}
