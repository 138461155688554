@import "src/styles/base/variables";

.wrap {
    display: flex;
    padding: 2em 0;
    border-bottom: 1px solid map_get($colors, darkGray);

    &:first-child {
        border-top: 1px solid map_get($colors, darkGray);
    }

    &:hover .title {
        color: map_get($colors, yellow);
    }
}

.date {
    border-right: 1px solid map_get($colors, darkGray);
    padding-right: 1em;
}

.dateD,
.dateM,
.dateY {
    color: map_get($colors, darkGreen);
    text-transform: uppercase;
}

.dateD {
    font-size: 1.75em;
    line-height: 1.2em;
}

.info {
    padding-left: 1em;
    max-height: 3em;
    overflow: hidden;
}

.title {
    font-weight: bold;
    color: map_get($colors, green);
    font-size: 1.3em;
    line-height: 1em;
    text-align: left;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
}

.description {
    color: map_get($colors, darkGray);
    text-align: left;
    padding-top: 0.25em;

    p {
        color: map_get($colors, darkGray);
        font-weight: 400 !important;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        margin: 0 !important;
    }
}

@media screen and (max-width: 768px) {
    .title {
        font-size: 1em;
    }

    .description,
    .dateM,
    .dateY {
        font-size: 0.8em;
    }

    .dateD {
        font-size: 1.25em;
    }
}
