@import './base/variables';
@import url('https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,400;0,600;1,400&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Cabin:wght@400;700&display=swap');

body {
  margin: 0;
  font-family: 'Rubik', sans-serif;
  font-size: 15px;
  line-height: 1.5; 
}

#root {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  min-height: 100%;
}

@keyframes slideInRight {
  0% {right:-300px}
  100%{right:0}
}

@keyframes slideInLeft {
  0% {right:0}
  100%{right:-300px}
}
.slideInRight {
  animation: slideInRight .4s;
}

.slideInLeft {
  animation: slideInLeft .4s;
}

input, 
button {
  outline: none;
}

.btn {
  display: flex;
  width: max-content;
  height: max-content;
  margin: 40px auto;
  padding: 17px 40px;
  background: map_get($colors, white);
  border: 1px solid map_get($colors, green);
  border-radius: 0;
  box-shadow: none;
  font-family: 'Rubik', sans-serif;
  font-size: 16px;
  color: map_get($colors, green);
  transition: .4s;

  &:hover,
  &:active,
  &:focus {
    background: map_get($colors, green);
    color: map_get($colors, white);
    border: 1px solid map_get($colors, green);
  }

  &-solid {
    background: map_get($colors, green);
    color: map_get($colors, white);    

    &:hover {
      background: map_get($colors, yellow);
      border: 1px solid map_get($colors, yellow);
    }
  }

  &-small {
    padding: 10px 20px;
    margin: 10px auto;
  }

  &-close {
    position: absolute;
    top: 20px;
    right: 80%;
    background: none;
    border: none;
    font-size: 30px;
    color: map_get($colors, green);
  }

  &-left {
    margin-left: 0;
    margin-right: auto;
  }
}

a {
  color: map_get($colors, black);
  text-decoration: none;

  &:hover {
    color: map_get($colors, yellow); 
  }
}

.search {
  display: flex !important;
  justify-content: center;
  max-width: 400px;
  margin: 40px auto;

  input {
    border-radius: 0;
            
    &:focus,
    &:hover {
      border-color: map_get($colors, green) !important;
    }
  }

  button {
    background: map_get($colors, green);
    border-radius: 0 !important;
    border-color: map_get($colors, green);
  }
}