@import 'src/styles/base/variables';

#root {
    .search-form {
        display: flex;
        padding: 8px 16px;
        margin: 0 8% 0 auto;
        border: 1px solid map_get($colors, green);
    
        &__icon svg {
            color: map_get($colors, green);
            margin-top: 8px;
            margin-right: 16px;
        }
    
        &__input {
            border: none;
        }
    
        &__input::placeholder {
            color: map_get($colors, green);
        }
    }

    .c-header .search-form {
        @media (max-width: 650px) { 
            display: none;
        }
    }
}

