@import 'src/styles/base/variables';

.title {
    color: map_get($colors, green);
    font-size: 2em;
    margin-top: 4rem;
}

.content {
    padding-top: 2em;
}

@media screen and (max-width: 768px) {
    .title {
        font-size: 1.3em;
    }
    
    .content {
        font-size: 0.8em;
    }
}