@import 'src/styles/base/variables';

.userPage {

    &__favorites .ant-checkbox-checked .ant-checkbox-inner {
        background: map_get($colors, green);
        border-color: map_get($colors, green);
    }

    &__search {
        display: flex !important;
        justify-content: center;
        max-width: 400px;
        margin: 40px auto;

        input {
            border-radius: 0;
            
            &:focus,
            &:hover {
                border-color: map_get($colors, green) !important;
            }
        }

        button {
            background: map_get($colors, green);
            border-radius: 0 !important;
            border-color: map_get($colors, green);
        }
    }

    &__date {
        font-size: 38px;
        margin-bottom: 40px;

        @media (max-width: 768px) {
            font-size: 22px;
        }
    }

    &__file.ant-list-item  {
        flex-direction: column;
        align-items: flex-start;  
        border-bottom: none !important;

        @media (min-width: 768px) {
            margin-bottom: -50px;
        }

        .ant-list-item-meta-avatar svg {
            height: 40px;
            width: 40px;
        }

        .ant-list-item-meta-content {
            width: 100%;

            h4 {
                font-size: 25px;
                font-weight: 600;
                color: map_get($colors, black);
            }
        }

        svg {
            color: map_get($colors, green);
        }
        
        .ant-list-item-action {
            width: 100%;
            box-sizing: border-box;
            flex: none;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            margin-left: 0;  

            .ant-space {
                flex-wrap: wrap;
            }
            
            li {
                padding: 0;
            }

            li:nth-of-type(1),
            li:nth-of-type(2) {
                padding-left: 55px;
            }

            li:nth-of-type(3) {

                @media (min-width: 768px) {
                    margin: auto;
                    margin-right: 30px;
                    transform: translateY(-60px);
                }               

                a.ant-btn {
                    padding-top: 10px;
                }

                .anticon.anticon-download {
                    padding-top: 6px;
                }
            }

            li:nth-of-type(4) {
                margin: auto;
                margin-right: 0;
                transform: translateY(-105px);

                @media (max-width: 768px) {
                    transform: translateY(-45px);
                }
            }

            em {
                display: none;
            }
        }
    }
}

.userNotificationsWrap > div {
    margin-top: 0;
    text-align: center;
}