.login {
  max-width: 1140px;
  padding: 0 20px;
  margin: 160px auto 40px;

  &__form {
    max-width: 450px;
    margin: 40px auto !important;
  }
  
  &__form label {
    display: block;
    color: #555;
    font-weight: 600;
  }
  
  &__input {
    width: 100%;
    box-sizing: border-box;    
    margin: 8px 0 15px;

    & > div {
      display: block;
    }

    .ant-form-item-label {
      width: 100%;
      text-align: left;
    }

    .ant-form-item-control input {
      padding: 10px;
      border: 1px solid #ddd;
      border-radius: 0;      
    }
  }
  
  &__form .login-form__checkbox {   
    display: inline;
    width: min-content; 
    font-weight: 400;
    color: #949494;
  }
  
  &__form__checkbox input {
    background: grey;
  }
}