@import 'src/styles/base/variables';

.container {
    display: flex;
    padding-left: 0;
    margin-bottom: 4rem;

    > li {
        margin-right: 1em;
        list-style: none;

        a:not(.active) {
            color: map_get($colors, green);

            &:hover {
                color: map_get($colors, yellow);
            }
        }
    }
}

.active > a {
    color: map_get($colors, darkGreen) !important;
    cursor: default;
}
