.l-admin {
    max-width: 1140px;
    padding: 0 20px;
    margin: 160px auto 40px;
    display: flex;

    &__block {
        width: 33.3%;
        min-height: 250px;
        transition: .4s;

        &:hover {
            box-shadow: 10px 10px 25px rgba(0, 0, 0, 0.2);

            a {
                font-size: 30px;
                transition: .3s;
            }
        }

        a {
            display: flex;
            height: 100%;
            width: 100%;
            font-size: 26px;
            justify-content: center;
            align-items: center;
            transition: .3s;

            @media (max-width: 768px) {
                justify-content: flex-start;
                padding: 0 15%;
            }
        }
    }

    &__icon {
        height: 40px;
        width: auto;
        margin-right: 20px;
    }
}

@media (max-width: 768px) {
    .l-admin {
        flex-direction: column;

        &__block {
            width: 100%;
            min-height: auto;

            a {
                font-size: 1em;
            }

            &:not(:last-child) {
                margin-bottom: 2rem;
            }
        }
    }
}