@import 'src/styles/base/variables';

.wrap {
    margin-top: 4rem;
}

.title {
    color: map_get($colors, green);
    margin-bottom: 2rem;
    font-size: 1.25rem;
}

:global {

    .ant-pagination-item-active {
        border-color: map_get($colors, green) !important;

        a {
        color: map_get($colors, green) !important;
        }
    }

}

@media screen and (max-width: 768px) {
    .title {
        font-size: 1.1em;
    }

    :global {
        .ant-table {
            overflow-x: scroll;
        }
        
        .ant-pagination {
            justify-content: center;
            
        }        

        .ant-table {
            font-size: 0.85em;
        }
    }
}