.wrap {
  max-width: 1140px;
  padding: 0 20px;
  margin: 160px auto 40px;
}

.list {
  margin: 4rem 0;
}

@media screen and (max-width: 768px) {
  .wrap {
    width: 100%;
    //padding: 1rem;
  }
}