@import 'src/styles/base/variables';

.c-sidebar {
    position: fixed;
    top: 0;
    z-index: 100;
    right: -400px;
    height: 100vh;
    width: 300px;
    background: map_get($colors, white);
    box-shadow: rgba(0, 0, 0, 0.2) 2px 8px 23px 3px;
    animation-duration: .4s;

    /*#advanced_search.search-form {
        display: none;

        
        @media (max-width: 650px) { 
            display: flex;
            margin: 80px 20px 0;
        }
    }*/

    &.open {
        right: 0;
        overflow-y: scroll;
    }

    &__menu {
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 80px;

        a:not(.btn) {
            font-size: 20px;
            margin-bottom: 15px;
        }
    }

    .btn {
        @media (max-width: 650px) { 
            margin: 20px 0;
        }
    }
}
