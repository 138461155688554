.mailing {
    max-width: 1140px;
    padding: 0 20px;
    margin: 160px auto 40px;

    &__content {
        width: 100%;
        padding-top: 50px;
    }

    .rdw-option-wrapper[title='Monospace'],
    .rdw-option-wrapper[title='Superscript'],
    .rdw-option-wrapper[title='Subscript'],
    .rdw-option-wrapper[title='Indent'],
    .rdw-option-wrapper[title='Outdent'],
    .rdw-option-wrapper[title='Ordered'] {
        display: none;
    }
}
