@import "src/styles/base/variables";

.c-header {
    position: fixed;
    top: 0;
    z-index: 90;
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    padding: 20px 8%;
    background: map_get($colors, white);

    &__logo img {
        // width: 103px;
        height: 57px;
    }

    &__button {
        position: fixed;
        top: 29px;
        right: 40px;
        padding: 0;
        background: none;
        border: none;
    }

    &__button svg {
        font-size: 30px;
        color: map_get($colors, green);
    }
}
