.wrap {
    display: flex;
    align-items: center;

    @media (max-width: 500px) {
        flex-direction: column;
        align-items: flex-start;
    }

    input {
        @media (max-width: 500px) {
            margin-top: 20px;
        }
    }
}

.search {
    width: 20rem !important;
}